// General

.svg-link {
  font-size: inherit;
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.svg-link svg {
  height: 1rem;
  vertical-align: top;
}

.text-link {
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}
.span-link {
  color: $blue;
  cursor: pointer;
  font-weight: 700;
}
.span-link:hover,
.span-link:active {
  text-decoration: underline;
  cursor: pointer;
}

.loading-fill-blue {
  background: linear-gradient(
    to top,
    $blue 10%,
    rgba(70, 42, 200, 0.2) 40%,
    rgba(70, 42, 200, 0.2) 60%,
    $blue 90%
  );
  background-size: auto 200%;

  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: lbfill 3s linear infinite;
  @-webkit-keyframes lbfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @keyframes lbfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
}

.loading-fill-grey {
  background: linear-gradient(
    to top,
    $darker-grey 20%,
    rgba(116, 113, 149, 0.3) 40%,
    rgba(116, 113, 149, 0.3) 60%,
    $darker-grey 90%
  );
  background-size: auto 200%;

  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: lgfill 3s linear infinite;
  @-webkit-keyframes lgfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 150%;
    }
    100% {
      background-position: 50% 300%;
    }
  }
  @keyframes lgfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 200%;
    }
  }
}

.loading-fill-purple {
  background: linear-gradient(
    to top,
    $purple 20%,
    rgba(160, 79, 213, 0.3) 40%,
    rgba(160, 79, 213, 0.3) 60%,
    $purple 90%
  );
  background-size: auto 200%;

  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: lpfill 3s linear infinite;
  @-webkit-keyframes lpfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
  @keyframes lpfill {
    0% {
      background-position: 50% 0%;
    }
    50% {
      background-position: 50% 200%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
}

.loading-data-gradient {
  animation: gradientFill 2s infinite linear;
  background-color: #fff;
  background-image: linear-gradient(90deg, #f0eff9, #fff, #f0eff9, #fff);
  background-size: 300% 100%;

  @keyframes gradientFill {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
}

.loading-fill-l2r {
  background: linear-gradient(90deg, rgba(116, 113, 149, 0.1), $darker-grey);
  background-size: 300%;

  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: filll2r 2s linear infinite;
  @keyframes filll2r {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: loading-ellipsis-animation steps(4, end) 900ms infinite;
  animation: loading-ellipsis-animation steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes loading-ellipsis-animation {
  to {
    width: 20px;
  }
}

@-webkit-keyframes loading-ellipsis-animation {
  to {
    width: 20px;
  }
}

//InfoCard
.info-card-row {
  margin-top: calc(-2.4rem - 12px);
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.info-card-bg-orange {
  background-color: #de7c55;
}

.info-card-bg-magenta {
  background-color: #cd5b8d;
}
.info-card-bg-purple {
  background-color: #a04fd5;
}
.info-card-bg-blue {
  background-color: #543fed;
}
.info-card {
  border-radius: 1rem;
}
.info-card .card-body {
  height: 58px;
  padding-right: 1rem;
  padding-left: 1rem;
}
.info-card .card-body .row {
  height: 58px;
}
.info-card .card-body .h4 {
  line-height: 0.9;
}
.info-card .card-header {
  margin-top: -0.6rem;
  font-size: smaller;
  font-weight: bold;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom: none;
  background-color: #faf9ff;
}
.info-card.info-card-bg-orange .card-header {
  background-color: lighten(#de7c55, 10%);
}
.info-card.info-card-bg-magenta .card-header {
  background-color: lighten(#cd5b8d, 10%);
}
.info-card.info-card-bg-purple .card-header {
  background-color: lighten(#a04fd5, 10%);
}
.info-card.info-card-bg-blue .card-header {
  background-color: lighten(#543fed, 10%);
}

.info-card .card-header .info-card-ellipse-container {
  float: right;
}
.info-card
  .card-header
  .info-card-ellipse-container
  .info-card-ellipse-dot.text-white.inactive {
  opacity: 0.4;
}
.info- .info-card .card-header .card-title-value {
  font-weight: bold;
  vertical-align: middle;
}
.info-card .infoCardValue.h3 {
  line-height: 2rem;
}
.info-card .infoCardValue.h2 {
  line-height: 3rem;
}
.info-card .card-header .card-title-icon {
  vertical-align: top;
}
.info-card .info-card-info-icon {
  width: 12px;
  height: 12px;
}
.info-card .info-card-info-icon.text-white path {
  stroke-opacity: 0.4;
  stroke: #fff !important;
}
.info-card .info-card-info-icon.text-white circle {
  stroke: #fff !important;
  stroke-opacity: 0.4;
}
.info-card .info-card-image {
  width: 30px;
  height: 30px;
}
span.info-card-image {
  display: inline-block;
  background-size: 100%;
}
.info-card .info-card-icon .info-card-image path {
  fill: #543fed !important;
}
.info-card .info-card-icon.text-white .info-card-image path {
  fill: #fff !important;
  fill-opacity: 0.4;
}
.info-card .infoCardSubscript {
  line-height: 13px;
}
.info-card .loading-stub {
  width: 16px;
  height: 150%;
  background-color: #fff;
  vertical-align: top;
}
.info-card-loading-value {
  height: 16px;
  width: 90%;
}
.info-card-loading-subscript {
  height: 8px;
  width: 90%;
}

//Profile Card
.profile-card {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-bottom: 48px;
  .profile-card-body {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    .row {
      min-height: 32px;
      line-height: 32px;
      svg {
        height: 32px;
        vertical-align: text-bottom;
      }
    }
  }
  .profile-subcard .card-header img.avatar {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
  .profile-subcard .card-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}
.profile-card .profile-subcard {
  border-radius: 1rem;
  .card-header img {
    height: 75px;
    width: 75px;
  }
}

// Performance Chart
.performance-chart-row {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.chart-svg {
  background: url('../../img/icons/cards/Performance.svg') center no-repeat;
  width: 20px;
  height: 15px;
  display: inline-block;
}
.chart-legend-line1 .fa-circle {
  color: #f7b500;
}
.chart-legend-bar .fa-circle {
  color: #cd5b8d;
}
.chart-legend-line2 .fa-circle {
  color: #543fed;
}

// Common Chart
.common-chart-row {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.common-chart-row .chart-svg {
  background: url('../../img/icons/cards/Performance.svg') center no-repeat;
  width: 20px;
  height: 15px;
  display: inline-block;
}

//Team Table

.team-table .team-table-search {
  width: 33% !important;
  box-shadow: none !important;
}
.team-table .team-table-header th {
  min-width: 100px;
  border-top: none;
  border-right: none;
  border-left: 1px solid #e6e5ef;
  border-bottom: none;
  background-color: inherit;
  color: inherit;
}
.team-table .team-table-header th:first-child {
  border: none;
}
.team-table .team-table-header th.sortable {
  cursor: pointer;
}
.team-table .team-table-row {
  border: none;
}
.team-table .team-table-row td {
  border-top: none;
  border-right: 1px solid #f0eff9;
  border-left: none;
  border-bottom: 1px solid #f0eff9;
}
.team-table .team-table-row td:last-child {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #f0eff9;
}
.team-table .team-table-prospects-cell.empty {
  color: #f97171 !important;
}
.team-table .team-table-page-button .active {
  color: #543fed;
}
.team-table .team-table-page-button .inactive {
  color: #e6e5ef;
}
.team-table .action-list-button {
  width: 90px;
}
// Events List
.event-list-pagination-page a {
  color: #e6e5ef;
}
.event-list-pagination-prev a {
  color: #543fed;
}
.event-list-pagination-next a {
  color: #543fed;
}
.event-list-pagination-item.active a {
  color: #543fed;
}
.event-list-pagination-item.disabled {
  display: none;
}
.event-list-pagination-item {
  cursor: pointer;
}
.event-list-pagination-item:hover a {
  text-decoration: none;
}
.events-list-event-chronlology {
  cursor: pointer;
  border-bottom: solid 2px;
  display: inline-block;
  min-width: 135px;
  text-align: center;
}
.events-list-event-chronlology.inactive {
  border-color: #c5c1e9;
  color: #202022;
  border-bottom: solid 2px #c5c1e9;
}
.events-list-event-chronlology.active {
  color: #462ac8;
}

// Networking Badge
.event-network-badge-container {
  .event-network-badge-row {
    border-radius: 0.25rem;
    overflow: hidden;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    min-width: 540px;
    .event-network-badge-label-col {
      color: $white;
      background-color: $darker-grey;
      font-size: 1.4rem;
      font-weight: bold;
      height: 100px;
      line-height: 100px;
      padding-left: 2rem;
      svg {
        margin-left: 3rem;
      }
    }

    @include media-breakpoint-down(lg) {
      .event-network-badge-label-col {
        text-align: center;

        font-size: 1.2rem;
        svg {
          margin-left: 1rem;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .event-network-badge-label-col {
        text-align: center;

        font-size: 1.5rem;
        svg {
          margin-left: 3rem;
        }
      }
    }
    .event-network-badge-button-col {
      height: 100px;
      background-color: $white;
      position: relative;
      @include media-breakpoint-up(lg) {
        .event-network-badge {
          position: absolute;
          margin-top: 20px;
          right: 50px;
          width: 70%;
        }
      }
      @include media-breakpoint-down(lg) {
        .event-network-badge {
          margin: 20px auto;
          width: 450px;
        }
      }
      .event-network-badge {
        min-width: 450px;
        .event-network-badge-label {
          width: 90%;

          .event-network-badge-icon {
            height: 30px;
          }
          .event-network-badge-text {
            width: 80%;
            display: inline-block;
            text-align: right;
            margin-right: 15px;
          }
          border-radius: 0.25rem;
          border: 1px solid $lightest-grey;
          line-height: 60px;
          font-weight: bold;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          height: 60px;
        }
      }

      @include media-breakpoint-up(lg) {
        .event-network-badge-activate {
          position: absolute;
          margin-top: 20px;
          right: 50px;
        }
      }
      @include media-breakpoint-down(lg) {
        .event-network-badge-activate {
          margin: 20px auto;
          width: 450px;
        }
      }
      .event-network-badge-activate {
        cursor: pointer;
        min-width: 450px;
      }
      .event-network-badge-button-label {
        .event-network-badge-icon {
          height: 30px;
          path {
            fill: $white;
          }
        }
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        line-height: 60px;
        font-weight: bold;
        color: $white;
        height: 60px;
        background: linear-gradient(133.94deg, #543fed 1.78%, #462ac8 100%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
      .event-network-badge-button-cost {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        line-height: 60px;
        font-weight: bold;
        color: $white;
        height: 60px;
        background: linear-gradient(
          112.43deg,
          #543fed -18.44%,
          #543fed 178.62%,
          #543fed 178.64%
        );
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

//  Timeline
.timeline-touches::before {
  width: 1px !important;
  color: #5a5878;
  background-color: #5a5878 !important;
  height: 94% !important;
  bottom: 0 !important;
  top: 6% !important;
}

.timeline-touches .timeline-touches-icon-container {
  line-height: 60px;
}
.timeline-touches .timeline-touches-icon-container img {
  max-height: 60px;
  max-width: 60px;
}
.timeline-touches .timeline-touches-element-year {
  line-height: 60px;
  overflow: visible;
}
.timeline-touches .timeline-touches-icon-container svg {
  height: 50px;
  width: 50px;
  line-height: 60px;
  left: 30%;
  top: 30%;
  max-height: 60px;
  max-width: 60px;
}
.timeline-touches .timeline-touches-icon-container i {
  line-height: 60px;
  max-height: 60px;
  max-width: 60px;
}

.timeline-touches .timeline-touches-icon-container span {
  line-height: 60px;
  max-height: 60px;
  max-width: 60px;
}
.timeline-touches .timeline-touches-element-details {
  font-size: 80%;
  font-weight: 400;
  line-height: 15px;
}

// Common Table
.common-table .search-label {
  width: 33%;
}
.common-table .search-label .common-table-search {
  width: 100% !important;
}
.common-table .common-table-search {
  width: 33% !important;
}
.common-table .common-table-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.common-table .common-table-header th {
  border-top: none;
  border-right: none;
  border-left: 1px solid #e6e5ef;
  border-bottom: none;
  background-color: inherit;
  color: inherit;
}
.common-table .common-table-header th:first-child {
  border: none;
}
.common-table .common-table-header th.sortable {
  cursor: pointer;
}
.common-table .common-table-row {
  border: none;
  font-weight: 700;
}
.common-table .common-table-row td {
  border-top: none;
  border-right: 1px solid #f0eff9;
  border-left: none;
  border-bottom: 1px solid #f0eff9;
}
.common-table .common-table-row td:last-child {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #f0eff9;
}
.common-table .common-table-page-button .active {
  color: #543fed;
}
.common-table .common-table-page-button .inactive {
  color: #e6e5ef;
}

.common-table .common-table-image {
  height: 1.5rem;
  width: 1.5rem;
}
.common-table-wrapper .common-table-csv-export-button {
  background: none;
  color: $blue;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: none !important;
  text-transform: uppercase;
}
.common-table-wrapper .common-table-csv-export-button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.common-table-wrapper .common-table-csv-export-button:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.common-table-wrapper .common-table-csv-export-button:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.event-table-csv-export-button {
  background: none !important;

  color: $blue !important;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: none !important;
  text-transform: uppercase;
}

.event-table-csv-export-button .vdxSvgIcon {
  width: 31px;
  height: 29px;
  margin-right: 8px;
}

// Tags

.trending-tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.trending-tags li {
  float: left;
}

.trending-tag {
  background: #f0eff9;
  border-radius: 0 3px 3px 0;
  color: #525f7f;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 12px 0 12px;
  position: relative;
  margin: 0 10px 10px 10px;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  cursor: pointer;
}

.trending-tag::after {
  background: #faf9ff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 0px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.trending-tag::before {
  background: #faf9ff;
  border-bottom: 13px solid transparent;
  border-right: 10px solid #f0eff9;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
}

.trending-tag:hover {
  background-color: #c5c1e9;
  color: #faf9ff;
}

.trending-tag:hover::before {
  border-right-color: #c5c1e9;
}

//Team Budget
.team-budget .team-budget-legend {
  font-size: 10px;
  text-align: right;
}
.team-budget .team-budget-legend .fa {
  line-height: 14px;
  vertical-align: top;
}
.team-budget .team-budget-legend .fa.fac-spend {
  color: $red-purple;
}
.team-budget .team-budget-legend .fa.fac-allocated {
  color: $purple;
}
.team-budget .team-budget-legend .fa.fac-remaining {
  color: #79bed9;
}
.team-budget .team-budget-legend .fa.fac-over {
  color: $light-blue;
}
.team-budget .budget-remaining {
  background-color: #79bed9;
}
.team-budget .budget-over {
  background-color: $light-blue;
}

// Input Popups

.base-input-popup.modal-dialog {
  max-width: 1000px;
}
.base-input-popup .modal-body {
  padding: 0;
}

.base-input-popup-header {
  color: $black;
  background-color: $medium-grey;
  border: none;
}
.base-input-popup-header.modal-header button {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}
.base-input-popup-header-graphic {
  width: 60%;
  margin: 0 auto;
}
.base-input-popup-header-graphic svg {
  max-width: 100%;
}
.base-input-popup-header-info {
  color: #5a5878;
  padding: 1rem;
}
.base-input-popup-header .title {
  color: $darkest-grey;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.base-input-popup-header .label {
  font-size: 3rem;
  line-height: 2.9rem;
  font-weight: bold;
  margin: 2rem 0rem;
}

.base-input-popup-header .info {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
}
.base-input-popup-body {
  padding: 0rem 8rem;
}
.base-input-popup-form-group input {
  border: 1px solid #ced4da;
  box-shadow: none;
}
.base-input-popup-form-group label {
  font-size: 1.1rem;
  font-weight: 500;
  color: #454545;
}

.base-input-popup-section {
  border-bottom: 1px solid #d8d8d8;
  padding: 1rem 0rem;
  margin: 2rem 0rem;
}
.base-input-popup-section-label {
  font-size: 1.5rem;
  font-weight: bold;
  color: #383737;
}

.base-input-popup-form-submit {
  width: 100%;
  color: #fff !important;
  padding: 0.7rem !important;
  background-color: none !important;
  background: linear-gradient(
    133.94deg,
    #543fed 1.78%,
    #462ac8 100%
  ) !important;
  margin-top: 5rem;
  margin-bottom: 3rem;
}
.base-input-popup-footer {
  border: none;
}

.base-input-popup .base-input-popup-pane-select-button {
  cursor: pointer;
  display: inline-block;
  min-width: 105px;
  text-align: center;
  border-color: #c5c1e9;
  color: #202022;
  border-bottom: solid 2px #c5c1e9;
  font-size: 0.8rem;
  width: 100%;
}
.base-input-popup .base-input-popup-pane-select-button.inactive {
  border-color: #c5c1e9;
  color: #202022;
  border-bottom: solid 2px #c5c1e9;
}
.base-input-popup .base-input-popup-pane-select-button.active {
  border-color: #462ac8;
  color: #462ac8;
  border-bottom: solid 2px #462ac8;
}
.base-input-popup-header-select-buttons {
  position: absolute;
  margin-top: -5rem;
}
.base-input-popup-header-select-button {
  background: #ffffff;
  border: 1px solid #c0bddc;
  box-sizing: border-box;
  border-radius: 4px;
  color: #454545;
  cursor: pointer;
  font-weight: bold;
  width: 96px;
  padding: 0.5rem;
  font-size: 0.75rem;
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
}
.base-input-popup-header-select-button.active {
  background: #462ac8;
  border: 1px solid #c0bddc;
  color: #fff;
}
.base-input-popup-local-modal-wrapper button.close {
  position: absolute;
  top: 5px;
  right: 17px;
}
.base-input-popup-local-modal-wrapper {
  position: absolute;
  width: 80%;
  display: inline-block;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .base-input-popup-local-modal-content {
    position: absolute;
    margin: auto;
    margin-top: 64px;
    z-index: 1111;
    display: inline-block;
    width: 100%;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid rgba(32, 32, 34, 0.2);
    border-radius: 0.3rem;
    overflow: hidden;
  }
  .base-input-popup-local-modal-content .base-input-popup {
    width: 100%;
    margin: 0;
  }
}
.base-input-popup-local-backdrop {
  width: 100%;
  height: 100%;
  background: rgba(90, 88, 120, 0.7);
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  .base-input-popup.popup-join,
  .base-input-popup.popup-confirm {
    margin: 1.75rem auto;
    width: 90%;
  }
}
.popup-join-wrapper.section_open.base-input-popup-local-wrapper {
  min-height: 850px;
}
.popup-join-local-wrapper {
  width: 60%;
  min-width: 760px;
}
.base-input-popup.popup-join,
.base-input-popup.popup-confirm {
  // min-width: 760px;
  background-color: inherit;
  z-index: 1111;
  width: 100%;
  border: none;
  background-color: inherit;
  border-radius: 0;
  position: relative;

  .popup-join-header {
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    h2 {
      font-weight: 700;
      font-size: 60px;
      line-height: 57px;
      color: $black;
    }

    p {
      font-weight: 400;
    }
  }
  .popup-join-header {
    border-bottom: none;
    color: inherit;
    padding: 32px 60px;
  }
  .popup-join-body {
    padding: 32px 60px 0 60px;
    background-color: $white;
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 40px;
  }
  img {
    width: 338px;
    height: 338px;
  }
  .form-group {
    border: none;
    box-sizing: initial;
    border-radius: initial;
  }

  .btn {
    width: 100%;
    padding: 1em;
    margin-bottom: 12px;
  }
  .join-terms-bold {
    font-weight: bold;
    color: $black;
  }
  .popup-join-footer {
    background: none;
  }
  .popup-join-close {
    color: $black;
    cursor: pointer;
  }
}

.budget-popup-updating-button-label {
  width: 70px;
}
.budget-popup-exit-link {
  color: $blue;
}

// Base Form Elements

.base-form-element-group .input-group input {
  display: block !important;
  width: 1% !important;
}
.base-form-element-group .input-group label {
  display: block !important;
  width: 100% !important;
}
.base-form-element-group .input-group.appended-elements input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.base-form-element-group .input-group.prepended-elements input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.base-form-element-group.is-invalid .input-group .input-group-text {
  background-color: theme-color-level(danger, -10);
  color: theme-color('danger');
  border-color: theme-color('danger');
}
.base-form-element-group.is-valid .input-group .input-group-text {
  background-color: theme-color-level(success, -10);
  color: theme-color('success');
  border-color: theme-color('success');
}
.base-form-element-group.is-invalid .invalid-feedback {
  display: block;
}
.base-form-element-group.is-valid .valid-feedback {
  display: block;
}
// ROI Gauge
svg.roiGauge {
  width: 100%;
  @include media-breakpoint-up(lg) {
    // width: 60%;
  }
}
// Background Gradients
.bg-purple-gradient {
  background-image: linear-gradient(
    225deg,
    #932994 0%,
    #591796 100%
  ) !important;
}
.bg-grey-gradient {
  background-image: linear-gradient(
    133.94deg,
    #d8d8d8 1.78%,
    #979797 100%
  ) !important;
}
