/* Font styles */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1, .h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

b, strong {
  font-weight: 600;
}
