:root {
  //<Blues>
  --vdx-color: #543fed;
  --vdx-blue-gradient: linear-gradient(97deg, #543fed 22%, #462ac8 102%);
  --vdx-blue: #462ac8;
  --vdx-light-blue-color: #f1f1fc;
  //</Blues>

  //<Purples>
  --vdx-red-purple: #cd5b8d;
  --vdx-purple: #a04fd5;
  --vdx-purple-gradient: linear-gradient(225deg, #932994 0%, #591796 100%);
  //</Purples>

  //<Oranges>
  --vdx-yellow-orange: #f7b500;
  --vdx-red-orange: #da7263;
  --vdx-orange: #de7c55;
  //</Oranges>

  //<Misc>
  --vdx-green-color: #39ba3a;
  --vdx-salmon: #f97171;
  //</Misc>

  //<LightDarks>
  --vdx-light-color: #ffffff;
  --vdx-dark-color: #000000;
  --vdx-black: #202022;
  --vdx-muter-color: rgba(0, 0, 0, 0.38);
  //</LightDarks>

  //<GreyShades>
  --vdx-grey-color: #9e9e9e;
  --vdx-grey-gradient: linear-gradient(133.94deg, #d8d8d8 1.78%, #979797 100%);
  --vdx-light-grey: #faf9ff;
  --vdx-lightest-grey: #f2f1fc;
  --vdx-medium-grey: #f0eff9;
  --vdx-darker-grey: #747195;
  --vdx-darkest-grey: #5a5878;
  //</GreyShades>

  // <NumberedGreys>
  --vdx-grey-100: #f6f9fc;
  --vdx-grey-200: #e9ecef;
  --vdx-grey-300: #dee2e6;
  --vdx-grey-400: #ced4da;
  --vdx-grey-500: #adb5bd;
  --vdx-grey-600: #8898aa;
  --vdx-grey-700: #525f7f;
  --vdx-grey-800: #32325d;
  --vdx-grey-900: #212529;
  // </NumberedGreys>
}

// hack to reduce the lcp on mobile
h3 {
  @media (max-width: 768px) {
    font-size: 1.2rem !important;
  }
}

h5 {
  @media (max-width: 768px) {
    font-size: 1rem !important;
  }
}

// hack to adjust total row count padding.
.MuiDataGrid-rowCount {
  /* Add your custom styles here */
  margin-right: 8px !important;
  /* Additional styling properties */
}

.css-lt3lpj-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-lt3lpj-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: 0;
  outline-width: 1px;
  outline-offset: -1px;
}
