.my-prospects {
  padding-top: 2.5rem;
  .my-prospects-card {
    background-color: transparent;
    padding: 0;
    border: none;
  }
}

.prospect-list {
  #prospect-return {
    text-align: right;
  }
  h1 {
    color: #202022;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 1.3rem;
  }
  .prospect-list-header {
    background-color: #f0eff9;
    border-radius: 0px 0px 24px 24px;
    padding: 20px;
  }
  img.avatar {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
}

.prospects-list-card {
  .card-header {
    background-color: #fff;
    position: relative;
    height: 100px;
    overflow: hidden;
  }
  h5 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    a {
      color: #525f7f;
    }
  }
  .close-x {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .card-body {
    height: auto;
    .row {
      padding-bottom: 2em;
    }
  }

  .card-footer {
    padding: 0px;
    background-color: #fff;
  }
}

.letter-circle {
  font-size: 0.7em;
  text-align: center;
  width: 1.9em;
  height: 1.9em;
}

.image-circle {
  width: 1.5em;
  height: 1.5em;
}

.prospect-csv-upload-table {
  th.prospect-csv-upload-table-data-item {
    width: 66%;
  }
  td {
    border-top: none;
    border-bottom: 1px dashed $blue;
  }
  select {
    padding: 10px !important;
  }
}

#prospectlistsearchbyname {
  .search .react-tags__suggestions {
    margin: 0 auto;
    width: 100%;
    left: 0;
    right: 0;
    top: 44px;
  }

  .search .react-tags__suggestions ul {
    padding: 0px !important;
    margin: 0px !important;
    background: white;
    width: 100%;
    border: 1px solid $medium-grey;
    border-top: none;
  }

  .search .react-tags__suggestions ul li:last-child {
    border-bottom: none;
  }

  .search .react-tags__suggestions li {
    padding: 8px !important;
    margin: 0px !important;
    list-style-type: none;
    background-image: none;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;
  }

  .search .react-tags__suggestions li:hover {
    background-color: $light-grey;
    font-weight: 500;
    border-bottom: 1px solid $medium-grey;
    border-top: 1px solid $medium-grey;
  }
}
