

/*

.table {
  .thead-light th {
    background-color: $medium-grey;
    color:$darkest-grey;
    font-size:16px;
    font-weight:600;
  }

  td
  {
    color:$darker-grey;
    font-size:16px;
    font-weight:500;
    border-top:#faf9ff;
  }
}

.page-link {
  border:none;
}

*/


.setup-prospects {
  thead th {
    border:none;
    background:none;
  }
  td {
    border:none;
    background:none;
  }
}





.event-row {
background: white;
padding: 20px;
margin: 0px 10px 15px 10px;
}
