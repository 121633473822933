.modal-std-rgn {
  .modal-std-rgn-header {
    z-index: 99999;
    background: none;
    button.close {
      color: #3620d8;
      font-weight: normal;
      font-size: 2rem;
      opacity: 1;
    }
  }
  .modal-std-rgn-body .modal-std-rgn-formatted {
    .row {
      min-height: 500px;
      .col {
        padding: 5rem 4rem !important;
      }
    }

    .basic {
      background-color: $white;
    }
    .fancy {
      background: url('../../img/brand/bg-tilted-gradient-logo.svg') left bottom
        no-repeat;
      background-color: #efedff;
      .section-icon-container {
        left: -2.7em;
        svg {
          vertical-align: top;
        }
      }
    }
    button.btn {
      background-color: $green !important;
      color: $white;
      background-image: none;
      border-radius: 2rem;
      width: 100%;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    button.btn-red {
      background-color: $red !important;
    }
    button.btn-grey {
      background-color: $darker-grey !important;
    }
  }
}

.modal-std {
  // width: 100%;
  @include media-breakpoint-up(sm) {
    width: 75%;
  }
  max-width: 1140px;
  min-width: 320px;
  .modal-content {
    border-radius: 0px;
  }
}
.modal-rgn-wrapper {
  min-height: 850px;

  .modal-rgn {
    width: 80%;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .content {
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin: auto;
      z-index: 1111;
      background-color: $white;
      background-clip: padding-box;
      border-radius: 0px;
      overflow: hidden;
    }
    .card {
      margin: 0;
      border-radius: 0px;
    }
  }
}
.modal-rgn-backdrop {
  background: rgba(90, 88, 120, 0.7);
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Include modal instances
@import 'modal.instances';
