// Delete Prospect List Modal
.prospect-list-delete-modal {
  max-width: 700px;

  .modal-std-rgn-body .row {
    min-height: 300px;

    .row {
      min-height: 0px;

      .col {
        padding: 0.5rem 1rem !important;
      }
    }
  }
}

// Single Button Modal
.single-button-modal {
  .modal-content {
    max-width: 540px;
    margin: 20% auto;
    background-color: $lightest-grey;
    position: relative;

    .info {
      background-color: $white;
      border: 1px solid $light-grey;
      width: 90%;
      margin: 0 auto;
      min-height: 200px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      position: relative;

      button {
        height: 50px;
        margin-top: 3rem;

        .icon {
          height: 20px;
          float: left;

          path {
            fill: $white;
          }
        }
      }
    }

    .close {
      cursor: pointer;
    }
  }
}
