.navbar-top {
  background: #543fed;

  .avatar img {
    width: 75px;
    border-radius: 50%;
  }
}

#adminNavBar .navbar-vdx-logo-container {
  text-align: center;
}

#adminNavBar .navbar-vdx-logo {
  height: 50px;
  vertical-align: middle;
}

#adminNavBarSearchGroup .search .react-tags__suggestions {
  margin: 0 auto;
  // width: 326px;
  left: 0;
  right: 0;
  top: 35px;
}

#adminNavBarSearchGroup .search .react-tags__suggestions ul {
  padding: 0px !important;
  margin: 0px !important;
  background: white;
  border: 1px solid $medium-grey;
  border-top: none;
}

#adminNavBarSearchGroup .search .react-tags__suggestions ul li:last-child {
  border-bottom: none;
}

#adminNavBarSearchGroup .search .react-tags__suggestions li {
  padding: 8px !important;
  margin: 0px !important;
  list-style-type: none;
  background-image: none;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid $white;
  border-top: 1px solid $white;
  color: $darker-grey;
}

#adminNavBarSearchGroup .search .react-tags__suggestions li:hover {
  background-color: $light-grey;
  font-weight: 500;
  border-bottom: 1px solid $medium-grey;
  border-top: 1px solid $medium-grey;
}

#adminNavBar #adminNavBarMenu {
  float: left;
}

#adminNavBar #adminNavBarMenu .nav-item a {
  font-size: 1rem;
  font-weight: 500;
  color: $white;
  transition: 0.3s;
}

#adminNavBar #adminNavBarMenu .nav-item a:hover {
  color: #d7e1fe;
}

#adminNavBar #adminNavBarMenu .nav-item a .admin-navbar-link-icon {
  padding: 0.5rem;
  cursor: pointer;
}

#adminNavBar #adminNavBarMenu .nav-item a .admin-navbar-link-icon svg {
  height: 1.5rem;
  width: 1rem;
  vertical-align: middle;
}

#adminNavBar #adminNavBarUserItem .avatar img {
  width: 40px;
}

#adminNavBar #adminNavBarRightMenu .nav-item a .admin-navbar-link-icon {
  padding-left: 0.5rem;
}
#adminNavBar #adminNavBarRightMenu .nav-item a .admin-navbar-link-icon svg {
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
}

#adminNavBar #adminNavBarRightMenu #alertsIconListItem {
  padding-right: 0.5rem;
  margin-top: -0.25rem;
  cursor: pointer;
}

#userImageListItem {
  .dd-item {
    display: flex;
    align-items: center;
    span {
      margin-left: 8px;
    }
  }

  .user-image-container {
    .avatar {
      img {
        width: 40px;
        border-radius: 50%;
      }
    }
  }
}

.not-logged-in-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  .sign-in-link {
    margin-right: 12px;
    font-weight: 500;
    font-size: 1rem;
    &:hover {
      color: #d7e1fe;
    }
  }
  .join-button {
    background-color: transparent !important;
    background-image: none;
    border: 1px solid #8475f3;
    color: #fff;
    font-weight: 500;
    border-radius: 0.3rem;
  }
}

.custom-lock-icon {
  font-size: 13px;
  margin-right: 8px;
}

.custom-nav-link {
  display: flex;
  align-items: center;
}

.vendelux-nav-toggle-icon .navbar-toggler-icon {
  background-image: url('../../img/theme/front/mobile-menu-button.svg');
}

#adminNavBar {
  .container-fluid {
    padding: 0 !important;
    display: none;
  }

  .container-fluid.show {
    display: block;
  }
}

.navbar-vdx-logo-container:not(.mobile-logo) {
  display: none;
}

#adminNavBarSearchGroup .react-tags-input-root,
#adminNavBarSearchGroup .search,
#adminNavBarSearchGroup .react-tags__search-input {
  width: 100%;
}

#adminNavBarMenu,
#adminNavBar .form-group,
#adminNavBarRightMenu {
  padding-left: 0.75rem;
  margin-top: 1.5rem;
}

#adminNavBarRightMenu #alertsIconListItem {
  display: none;
}

#adminNavBar .navbar-vdx-logo-container.mobile-logo {
  width: auto;
  height: auto;
  .navbar-brand {
    margin-right: 0;
    padding: 0;
  }
  .navbar-vdx-logo {
    margin-top: 0;
    height: 40px;
    width: 40px;
  }
}

@include media-breakpoint-up(lg) {
  #adminNavBar {
    .navbar-vdx-logo-container.mobile-logo {
      display: none;
    }
  }

  #adminNavBarMenu,
  #adminNavBar .form-group,
  #adminNavBarRightMenu {
    padding: 0;
    margin-top: 0;
  }

  #adminNavBarRightMenu #alertsIconListItem {
    display: none;
  }
  .navbar-vdx-logo-container:not(.mobile-logo) {
    display: block;
  }

  #adminNavBarSearchGroup {
    padding: 0;
    .search {
      width: 326px;
    }
  }

  #adminNavBar #adminNavBarMenu .nav-item a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  }
}

@include media-breakpoint-up(xl) {
  #adminNavBar {
    .navbar-vdx-logo-container {
      overflow: hidden;
    }

    .container-fluid {
      padding: 0 !important;
    }

    #adminNavBarRightMenu #userImageListItem {
      width: 6.4rem;
    }
  }

  #adminNavBarRightWrapper {
    margin-left: 3rem;
    margin-right: 3.75rem;
  }
}
