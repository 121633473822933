html, body {
  height:100%;
  margin:0;
}

body {
  font-family: 'Barlow', Helvetica, sans-serif;
  font-weight: 300;
  background-color: #faf9ff;
}

#root {
  display:flex;
  flex-direction:column;
  height:100%;
}

.main-content {
  flex-grow:1;
}

.navbar-top {
  .container-fluid {
    max-width: 1288px;
    margin: auto;

    .search {
      .react-tags__search-input {
        width: 326px;
      }
    }
  }
}

a {
  color: $blue;
}
.black {
  color: $black !important;
}

.bg-info {
  background-color: $medium-grey !important;
}

button.btn {
  background-image: $blue-gradient;
  color: $white;
  font-weight: 600;
}

button.btn.grn {
  background-color: $green !important;
  color: $white;
  background-image: none;
  border-radius: 2rem;
  padding: 0.75rem 3rem;
}

.container-fluid {
  padding: 26px 30px;
}

.auth-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: $lightest-grey;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.setup-sidebar {
  width: 620px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: $white;
  padding: 132px;
  h1 {
    font-size: 60px;
    color: $darkest-grey;
    font-weight: bold;
    line-height: 0.95;
  }
}
.setup-inner {
  .setup-center {
    height: 100vh;
    overflow: scroll;
    .vertical-center {
      min-height: initial;
      min-height: initial;
      .setup-prospects {
        margin-bottom: 280px;
      }

      @include media-breakpoint-up(sm) {
        min-height: 100%;
        min-height: 100vh;
        .container {
          padding-bottom: 150px;
        }

        .setup-prospects {
          margin-bottom: 0px;
        }
      }
    }
  }
  .input-group > .form-control {
    width: 100%;
  }
}

.setup-footer {
  border-top: 1px $light-grey solid;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 30px;
  z-index: 999;
  background: #f2f1fc;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.setup-progressbar {
  background-color: #363638;
  width: 300px;
  height: 6px;
  .progress-bar {
    background-color: #6dd400;
  }
}

button.btn-secondary {
  background-color: #f2f1fc !important;
  background-image: none;
  border: none;
  color: #454545;
}

li.dropdown {
  list-style: none;
  .nav-link {
    padding: 0px;
  }
}
.force-zindex {
  z-index: 99;
}

/* Test drive and get a free account */

.testdrive-splash {
  text-align: center;
  font-weight: 600;
  background: #f97171;
  color: #fff;
  padding: 0.5em;
  cursor: pointer;
}

.sidebar-test-overlay {
  display: block;
  position: fixed;
  width: 188px;
  height: 100%;
}

.popup-modal-join {
  width: 100%;
  height: 100%;
  background: rgba(90, 88, 120, 0.7);
  z-index: 999;
  min-height: 150vh;
  top: 0;
}
.modal-half {
  .popup-modal-join {
    min-height: 300vh;
  }
}
.popup-modal-confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(90, 88, 120, 0.7);
  z-index: 999;
  min-height: 150vh;
  top: 0;
  left: 0;
}
.popup-confirm {
  top: 0;
}
.popup-join,
.popup-confirm {
  position: absolute;
  width: 60%;
  margin: auto;
  margin-top: 170px;
  margin-left: 20%;
  background: #fff;
  z-index: 1111;
  .card-header {
    border-bottom: none;
    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    h2 {
      font-weight: 700;
      font-size: 60px;
      line-height: 57px;
    }
    padding: 60px;
    p {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .card-body {
    padding: 20px 60px;
  }
  h2 {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 40px;
  }
  img {
    width: 338px;
  }
  .form-group {
    border: 1px solid #c0bddc;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .btn {
    width: 100%;
    padding: 1em;
    margin-bottom: 50px;
  }
}

.event-header {
  background: #faf9ff;
  border-bottom: none;
  .btn {
    font-size: 12px;
  }
  .card {
    border-radius: 6px;
    border: 1px solid #f0eff9;
    background: transparent;
  }
  .card-header {
    background: transparent;
    color: #525f7f;
    border-radius: 6px 6px 0px 0px;
    border: none;
    font-weight: 600;
  }
  .card-body {
    padding-top: 0.25rem;
  }

  .event-header-icon-container {
    line-height: 1.5rem;
  }
  .event-header-icon-container svg {
    vertical-align: top;
    height: 1.5rem;
  }
  button.btn.event-attendance-button {
    background-image: none;
  }
  .event-attendance-button.active {
    background-color: $green !important;
    border: none !important;
    color: $white !important;
  }
  .event-attendance-button.inactive {
    background-color: $white !important;
    border: 1px solid $black !important;
    color: $black !important;
  }
  .event-attendance-button.inactive:hover {
    border-color: $darker-grey !important;
    color: $darker-grey !important;
  }
  .save-event-card-body {
    padding-top: 1.25rem;
  }
  .save-event-button-text {
    margin-left: 8px;
  }
}
.event-sponsorship-row {
  width: 96%;
  margin: 1rem auto;
  background-color: $white;
  .event-sponsorship-col {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .event-sponsorship-label {
    color: $white;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: $salmon;
  }
  .event-sponsorship-vertical-rule {
    height: 60px;
    width: 1px;
    background-color: $darkest-grey;
    margin: 20px auto;
  }
  .event-sponsorship-contact-button {
    height: 40px;
  }
  @include media-breakpoint-up(lg) {
    .event-sponsorship-material {
      padding-right: 0;
    }
    .event-sponsorship-contact {
      padding-left: 0;
    }
    .event-sponsorship-vr {
      display: block;
    }
  }
  @include media-breakpoint-down(lg) {
    .event-sponsorship-vr {
      display: none;
    }
  }
}

.app-tab {
  cursor: pointer;
  border-bottom: solid 2px;
  display: inline-block;
  min-width: 135px;
  max-width: 100%;
  text-align: center;
}
.app-tab.sm-app-tab {
  font-size: 0.8rem;
}
.app-tab.inactive {
  border-color: #c5c1e9;
  color: #202022;
  border-bottom: solid 2px #c5c1e9;
}
.app-tab.active {
  color: #462ac8;
}

/* Helpers */
.cursor-pointer {
  cursor: pointer;
}

.top-0 {
  top: 0;
}
