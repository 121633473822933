.card-profile {
  .card-header {
    background-color: #cfcfcf;

    .event-card img {
      min-height: 150px;
      object-fit: contain;
      background-color:white;
    }
  }

  .card-body {
    .event-card-body-inner {
      padding: 1.25rem;
      padding-top: 0px;
    }
  }
  margin-bottom: 30px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 1em 0;
    height: 48px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .hidden {
    display: none;
  }

  .rounded-circle {
    width: 26px;
    height: 26px;
  }

  div .rounded-circle {
    background: #777;
    color: #fff;
  }
}

.event-obscured {
  position: relative;
  .event-obscure-pane {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #5a5878;
    opacity: 0.7;
  }
  .event-card img {
    min-height: 150px;
    object-fit: cover;
  }
}

.event-obscure-list-pane {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #5a5878;
  opacity: 0.7;
}
