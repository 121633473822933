.feed {
  .event-visible {
    width: 100%;
  }

  .feed-main {
    margin-left: 0;
    margin-right: 0;
  }

  .card-profile h2 {
    min-height: auto;
    padding-bottom: 16px;
  }

  padding: 16px 0px 0px 0px;
  .feed-prospect-preview {
    border-radius: 40px 40px 40px 40px;
    width: 64px;
    height: 64px;
  }

  .user-info {
    .col-xl-1,
    .col-xl-10,
    .col-xl-11 {
      padding: 0px;
    }
    .row {
      margin: 0px;
    }
    .card {
      border: none;
      background-color: #faf9ff;
      .card-body {
        background: #f0eff9;
        border: none;
        small {
          margin-left: 8px;
        }
        h2 {
          font-size: 18px;
          font-weight: 700;
        }
      }
      .card-footer {
        h3 {
          font-size: 16px;
          font-weight: 500;
        }
        background: #ffffff;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 16px;
      }
    }
  }

  .feed-header {
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 0;
    font-weight: 700;
    h2 {
      font-size: 28px;
      font-weight: 300;
    }
  }

  .feed-calendar {
    padding: 6px 22px;
    background: #f0eff9;
    border: none;
    h2 {
      font-size: 28px;
      font-weight: 300;
      span {
        font-weight: 700;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
    }
    table {
      background: transparent;

      th {
        background: #efeff9;
        color: #525f7f;
        padding: 0 0 4px 0;
        border-bottom: 1px solid #bfc1d5;
      }

      td {
        padding: 4px 0 4px 0;
        border-bottom: 1px solid #bfc1d5;
      }
    }
  }

  .feed-prospects {
    padding: 6px 22px;
    background: #f0eff9;
    border: none;
    border-radius: 0 0 24px 24px;

    .row {
      margin-bottom: 8px;
    }

    h2 {
      font-size: 28px;
      font-weight: 300;
      span {
        font-weight: 700;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
    }
    img {
      border-radius: 50%;
      width: 40px;
    }
    button {
      width: 100%;
    }
  }

  .feed-buy-more-credits-button {
    font-size: 0.6rem;
  }
}
