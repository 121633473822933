.aaAutocomplete {
  position: relative;
  // z-index: 1;
}

.autocomplete--front {
}

.autocomplete--search {
}

.aaForm {
  border-radius: 50vh;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(128, 126, 163, 0.8);
  display: flex;
  line-height: 1em;
  margin: 0;
  position: relative;
  width: 100%;

  &.aaForm--small {
    height: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .aaInput {
    font-size: 16px;
  }
}

.aaFormFront {
  border-radius: 8px;
  align-items: center;
  background-color: #fff;
  border: none;
  display: flex;
  line-height: 1em;
  margin: 0;
  position: relative;
  width: 100%;
}

.aaInputWrapperPrefix {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 44px;
  order: 1;
}

.aaLabel {
  margin-bottom: 0;
  cursor: auto;
  flex-shrink: 0;
  height: 100%;
  padding: 0;
  text-align: left;

  svg {
    color: #3e34d3;
    height: auto;
    max-height: 20px;
    stroke-width: 1.6;
    width: 20px;
  }
}

.aaSubmitButton {
  height: 100%;
  padding-left: 11px;
  padding-right: 8px;
  width: 47px;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
}

.aaInputWrapper {
  order: 3;
  position: relative;
  width: 100%;

  .aaInput {
    line-height: 40px;
    height: 44px;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    color: #262627;

    padding: 0;
    width: 100%;
    box-shadow: none;
    font-family: 'Barlow', Helvetica, sans-serif;
    font-weight: 500;
  }
}

.aaInputWrapperSuffix {
  align-items: center;
  display: flex;
  height: 44px;
  order: 4;

  .aaClearButton {
    align-items: center;
    background: none;
    border: 0;
    color: rgba(128, 126, 163, 0.6);
    cursor: pointer;
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0 12.83333px;
  }
}

.aaInputWrapperSearchButton {
  align-items: center;
  display: flex;
  order: 4;
  margin: 3px;

  &.aaInputWrapperSearchButton--small {
    margin-right: -2px;
  }
}

.aaInputWrapperSearchButtonFront {
  align-items: center;
  display: flex;
  order: 4;
  margin: 0;
}

.aisSearchButton {
  border-radius: 60px;
  padding: 12px 18px;
  background: #39ba3a;
  color: #fff;
  font-weight: 600;
  border-color: transparent;
  cursor: pointer;

  &.aisSearchButton--small {
    height: 32px;
    padding-block: 0;
  }
}

.aisSearchButtonFront {
  border-radius: 8px;
  padding: 21px;
  background: #ed5340;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  width: 250px;
  border-color: transparent;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100px;
    font-size: 14px;
  }
  margin: 0;
}

.aaPanel {
  max-height: 400px;
  overflow: scroll;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin: 8px 0 0;
  position: absolute;
  transition: opacity 0.2s ease-in, filter 0.2s ease-in;
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.1),
    0 6px 16px -4px rgba(35, 38, 59, 0.15);
  z-index: 1;
}

.aaPanelLayout {
  height: 100%;
  margin: 0;
  max-height: 650px;
  overflow-y: auto;
  padding: 0;
  position: relative;
  text-align: left;
}

.aaPanelScrollable {
  margin: 0;
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
}

.aaSource {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.aaSourceHeader {
  margin: 8px 0.5em 8px 0;
  padding: 0;
  position: relative;
}

.aaSourceHeaderTitle {
  background: #fff;
  color: #3e34d3;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0;
  padding: 0 8px 0 0;
  position: relative;
  z-index: 9999;
}

.aaSourceHeaderLine {
  border-bottom: 1px solid #3e34d3;
  display: block;
  height: 2px;
  left: 0;
  margin: 0;
  opacity: 0.3;
  padding: 0;
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 9998;
}

.aaList {
  padding: 0;
  position: relative;
  list-style: none;
  margin: 0;
}

.aaItem {
  font-weight: 500;
  margin: 4px 4px 4px 8px;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  min-height: 40px;
  padding: 4px;
}

.aaItem[aria-selected='true'] {
  background-color: rgba(179, 173, 214, 0.205);
}

.aaItemWrapper {
  align-items: center;
  color: var(--vdx-dark-color);
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  // grid-gap: calc(var(--aa-spacing-half)/2);
  // gap: calc(var(--aa-spacing-half)/2);
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
}

.aaItemContent {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-gap: 8px;
  gap: 8px;
  // grid-gap: var(--aa-spacing-half);
  grid-auto-flow: column;
  line-height: 1.25em;
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
  :not(.fixedSearchBar) .aaForm.aaForm--small {
    width: 20em;
  }
}

@media only screen and (max-width: 600px) {
  .aaPanel {
    display: none;
  }
  .fixedSearchBar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 989;
    border-radius: 0;
    overflow: auto;

    .aaInputWrapperPrefix {
      display: none;
    }

    .aaForm {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #e4e4e4;
      padding: 8px;
      &:focus-within {
        box-shadow: none;
      }
    }

    .autocomplete--front {
      padding: 2px 2px 0 8px;
    }

    .aaFormFront {
      &:focus-within {
        box-shadow: none;
      }
      .aaInputWrapper {
        margin-right: 2px;
      }
    }

    .aaPanel {
      display: block;
    }
  }

  .aaPanel {
    max-height: initial;
    box-shadow: none;
  }
}
