.blur-hack {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
}

button.modify-btn {
  font-size: 16px;
}

input,
select {
  border-radius: 6px;
  border: none;
  padding: 0.75rem;
  width: 100%;
  background: #fff;
  color: #525f7f;
  font-weight: 300;
}
input::placeholder {
  opacity: 1;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select::-ms-expand {
  display: none;
}

input.border-0 {
  box-shadow: none;
}

.search {
  .form-control {
    height: auto;
  }
}

/* Custom Tags */

button.fake-tag {
  border: 0px;
  background: white;
  display: block;
  clear: both;
  margin-bottom: 5px;
  padding: 4px 14px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #747195;
}

button.react-tags__selected-tag {
  border: 0px;
  background: white;
  display: block;
  clear: both;
  margin-bottom: 5px;
  padding: 4px 14px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #747195;
}
button.react-tags__selected-tag span:before {
  content: '× ';
  cursor: pointer;
}
.react-tags__suggestions {
  position: absolute;
  margin-top: 2px;
  z-index: 99;
}

.react-tags__suggestions ul {
  padding: 20px !important;
  margin: 0px !important;
  background: white;
}
.react-tags__suggestions li {
  padding: 8px 0px 8px 32px !important;
  margin: 0px !important;
  list-style-type: none;
  background-image: url('../../img/theme/checkbox.png');
  background-repeat: no-repeat;
  background-position: 0px 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}
.react-tags__suggestions ul:last-child {
  border-bottom: none;
}

.react-tags__suggestions mark {
  border: none;
  background-color: inherit;
  padding: 0px;
  margin: 0px;
}

/* Custom Checkboxes */

.checklist-field {
  padding: 12px;
  color: #525f7f;
  background-image: url('../../img/theme/search-arrow-down.png');
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(222, 220, 241, 0.56);
}

input.custom-checkbox,
input.form-check-input {
  width: 0 !important;
}

.checklist-dropdown {
  z-index: 999;
  position: absolute;
  width: 85%;
  padding-top: 15px;
}
.checkbox-dropdown-item {
  margin-left: 2px;
}
.dropdown-section {
  font-weight: 600;
}

.dropdown-item {
  font-weight: 300;
}

.checklist-dropdown {
  label {
    display: block;
  }
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #c0bddc;
    border-radius: 4px;
  }
  .checkmark:after {
  }

  :hover input ~ .checkmark {
    background-image: none;
  }
  input:checked ~ .checkmark {
    background-image: url('../../img/theme/check.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.input-group-alternative {
  z-index: 99;
}

.save-popup {
  width: 300px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 30px;
  padding: 50px;
  background: white;
}
.save-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(90, 88, 120, 0.7);
  z-index: 999;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
