#searchMainWrapper{
    padding-top: 2.5rem;
    min-height: 600px;
}
#searchMainContainer{
    padding-right: 35px;
    padding-left: 10px;
}

#searchHeader{
    margin-bottom: 2rem;
    border-bottom-left-radius: 1.3rem;
    border-bottom-right-radius: 1.3rem;
    padding-bottom: 1rem;
}

#searchHeaderLabelContainer{
    padding-top: 18px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
}
#searchHeaderLabel{
    color: $black;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 1.3rem;
}
#searchMainWrapper #exploreEventsSearchButtonContainer{
    padding: 1rem 30px 0.5rem 30px;
}
#searchMainWrapper .explore-events-search-button-wrapper{
    position: relative;
    overflow: hidden;
}
#searchMainWrapper #exploreEventsSearchButton{
    padding: 9px 15px !important;
    font-weight: 600;
    position: relative;
    z-index: 10;
}

#searchMainWrapper .search-suggested-options{
    padding: 0;
    margin: 0.5rem 0 0 0;
}
#searchMainWrapper .search-suggested-option{
    font-size: .8rem;
    margin-right: 1rem;
    text-decoration: underline;
    color: $darker-grey;
    cursor: pointer;
    font-weight: 300;
}

#searchMainWrapper .explore-events-search-option-container .react-tags__selected-tag{
    margin: .2rem;
    display: inline-block;
    font-size: .8rem;
}
#searchMainWrapper .explore-events-search-option-container .react-tags__selected-tag-name::before{
    color: $blue;
}
#searchMainWrapper .fake-tag{
    margin: .2rem;
    font-size: .8rem;
}

#searchMainWrapper .search-clear-criteria{
    cursor: pointer;
    border: 1px solid #b7b3ca;
    background: none;
    color: #918ea0;
}
#searchMainWrapper .event-block-search-pic-container{
    font-size: .5rem;
}
#searchMainWrapper .event-block-search-pic{
    height: 20px;
    width: 20px;
}
#searchMainWrapper .event-block-search-pic-count{
    height: 20px;
    font-weight: bold;
    color: $blue;
    overflow: hidden;
}
#searchMainWrapper .event-block-search-pic-icon{
    height: 20px;
    width: 20px;
}
#searchMainWrapper .event-block-search-pic-error{
    background-color: $blue;
    color: $white;
    height: 20px;
    width: 20px;
    display: block;
    line-height: 20px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    opacity: 0;
}

#searchMainWrapper .event-block-search-pic-load-fail{
    background: $blue;
    color: $white;
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    display: none;
}
#searchMainWrapper .event-block-search-pic-load-error .event-block-search-pic-load-fail{
    display: block;
}

@include media-breakpoint-down(lg){
    #searchMainWrapper .search-option-label{
        margin-bottom: .5rem !important;
        margin-top: 2rem !important;

    }
    #searchMainWrapper .search-option{
        margin-top: 0 !important;
    }
}
