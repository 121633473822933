.vdxSvgIcon {
  width: 24px;
  height: 20px;
}

.vdxGradientPin {
  background: no-repeat url('../../img/icons/cards/GradientPin.svg');
}

.vdxGradientPocketBook {
  background: no-repeat url('../../img/icons/cards/GradientPocketBook.svg');
}

.vdxIndustryIcon {
  background: no-repeat url('../../img/icons/cards/Seniority.svg');
  background-size: 100%;
}

.vdxUrlIcon {
  background: no-repeat url('../../img/icons/cards/UrlIcon.svg');
}

.vdxTableIconSpeaker {
  background: no-repeat url('../../img/theme/TableIconSpeaker.svg');
  width: 20px;
}

.vdxTableIconProspect {
  background: no-repeat url('../../img/theme/TableIconProspect.svg');
  width: 20px;
}

.vdxCSVExportIcon {
  background: no-repeat url('../../img/theme/CSVExportIcon.svg');
}

.vdxNavLogo {
  background: no-repeat url('../../img/icons/Nav/navicon-logo.svg');
  width: 56px;
  background-size: 100%;
  background-position: center;
}

.vdxLogoBlue {
  background: no-repeat url('../../img/theme/blue-full-logo.svg');
  background-size: 100%;
  background-position: center;
}

.vdxNavLogoMobile {
  background: no-repeat url('../../img/icons/Nav/navicon-logo-mobile.svg');
  background-size: 100%;
  background-position: center;
}
.vdxPngWhiteLogo {
  background: no-repeat url('../../img/theme/vdx-logo-white.png');
  background-size: 100%;
  background-position: center;
}
.vdxNavBell {
  background: no-repeat url('../../img/icons/Nav/navicon-bell.svg');
}

.fullBackground {
  display: block;
  background-size: 100%;
}
