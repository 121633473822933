.bg-purple-card {
  background-image: url('../../img/theme/bg-purple-card.svg');
  background-repeat: no-repeat;
  background-position: center -3px;
  background-size: 100%;
}
body {
  //     background-image: url('../../assets/img/theme/About.svg');
  // background-color: #fff;
  // background-repeat: no-repeat;
  // background-position: center top;
}

#frontMainWrapper,
#frontTopWrapper {
  background-color: #fffff;
}
#frontTopWrapper.safariTopWrapper {
  background: $blue-gradient !important;
}
.front-bg-dark {
  background-color: #fafafa !important;
}

#frontTopWrapper {
  background-image: url('../../img/theme/front/front-background.webp');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding-bottom: 8rem;
  margin-top: -1px;
  min-height: 400px;
}

#frontHeadingViewImgRow {
  margin-top: -130px;
}
.front-heading-view-image img {
  border: 10px black solid;
  border-radius: 30px;
  box-shadow: 30px 30px 30px rgba(33, 37, 43, 0.3);
}

@include media-breakpoint-down(lg) {
  #frontTopWrapper {
    // background-size: 100% 52%;
  }
  #frontTopWrapper.short-background {
    //  background-size: 100% 35%;
  }
}

@include media-breakpoint-down(md) {
  #frontTopWrapper {
    // background-size: 100% 46%;
  }
  #frontTopWrapper.short-background {
    // background-size: 100% 29%;
  }
  #frontMainContaner {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  #frontMainWrapper .row {
    margin-right: 0;
    margin-left: 0;
  }
}

@include media-breakpoint-down(xs) {
  #frontTopWrapper.short-background.front-wrapper-mobile-nav-open {
    // background-size: 100% 39%;
  }
  #frontTopWrapper.front-wrapper-mobile-nav-open {
    //  background-size: 100% 55%;
  }
}
#frontTopContaner {
  padding-top: 2rem;
}

//General Elements
#frontMainWrapper button.front-button {
  background-color: rgb(92, 184, 92) !important;
  background-image: none;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 0.3rem;
}
#frontMainWrapper .btn.front-button {
  background-color: rgb(92, 184, 92) !important;
  background-image: none;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 0.3rem;
}
#frontMainWrapper .front-search-bar-input-group {
  margin: 0 auto;
}

#frontMainWrapper
  .front-search-bar-input-group
  .form-control.front-search-bar-input::placeholder {
  font-size: 1rem;
  font-weight: 500;
}

@include media-breakpoint-up(lg) {
  #frontMainWrapper .front-search-bar-input-group {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  #frontMainWrapper .front-search-bar-input-group input {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    z-index: 10;
    height: 58px;
    box-shadow: none;
    padding-left: 27px !important;
  }
  #frontMainWrapper .front-search-bar-input-group .autocomplete-wrapper {
    width: 80%;
    height: 3.6em;
  }
  #frontMainWrapper .front-search-bar-input-group .autocomplete-wrapper > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #frontMainWrapper .front-search-bar-input-group .input-group-append {
    margin-left: -5px;
  }
  #frontMainWrapper .front-search-bar-input-group .front-search-bar-button {
    z-index: 11;
    margin-left: -5px;
  }
  #frontMainWrapper .front-search-bar-input-group .btn {
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: 41px;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 3.6em;
  }
  #frontMainWrapper
    .front-search-bar-input-group
    .form-control.front-search-bar-input::placeholder {
    font-size: 0.9rem;
    font-weight: 500;
    color: #747195;
  }
}
@include media-breakpoint-down(lg) {
  #frontMainWrapper
    .front-search-bar-input-group
    .form-control.front-search-bar-input::placeholder {
    font-size: 0.7rem;
    font-weight: 500;
    color: #747195;
  }
}
@include media-breakpoint-down(md) {
  #frontMainWrapper .front-search-bar-input-group .front-search-bar-button {
    width: 100%;
    display: block;
    margin-top: 1rem;
  }
  #frontMainWrapper .front-search-bar-input-group .autocomplete-wrapper {
    display: block;
    width: 100%;
  }
  #frontMainWrapper .front-search-bar-input-group .btn {
    width: 100%;
  }
  #frontMainWrapper
    .front-search-bar-input-group
    .form-control.front-search-bar-input::placeholder {
    font-size: 0.8rem;
    font-weight: 500;
  }
}

//  NavBar
#front-hnb {
  padding-top: 0;
}
#front-hnb .front-nav-logo-icon {
  height: 38px;
  width: auto;
  margin-right: 16px;
}
#front-hnb .front-nav-text-image {
  height: 25px;
  vertical-align: initial;
}
#front-hnb .navbar-brand {
}
#front-hnb .front-mobile-navbar-toggler {
  border: 0;
}
#front-hnb .front-mobile-navbar-toggler-icon {
  background-image: url('../../img/theme/front/mobile-menu-button.svg');
}
#front-hnb .front-hnb-logo path {
  fill: rgba(255, 255, 255, 0.8);
}
#front-hnb .front-hnb-logo-text {
  margin-left: -6px;
  font-size: 35px;
  vertical-align: middle;
}
#front-hnb .front-hnb-nav-item {
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
}
#front-hnb .front-hnb-nav-item:last-child {
  padding-right: 0;
}
#front-hnb .front-hnb-link {
}
#front-hnb .front-hnb-link:hover {
  text-decoration: underline;
  text-decoration-color: rgb(165, 131, 255) !important;
}
#front-hnb .front-hnb-button {
  padding: 0.375rem 1rem;
}
@include media-breakpoint-down(md) {
  .front-hnb-logo-text {
    display: none;
  }
  #front-hnb .front-hnb-nav-item {
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }
}
@include media-breakpoint-down(sm) {
  #front-hnb .front-hnb-nav-item {
    font-size: 0.7rem;
  }
}
@include media-breakpoint-down(xs) {
  #front-hnb .front-hnb-nav-item {
    font-size: 0.9rem;
  }
}
// Heading
// .front-heading-view .front-heading-item{
//     margin-top: 3rem;
// }
.front-heading-view .front-heading-text {
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  line-height: 5rem;
  margin-top: 6rem;
  margin-bottom: 4rem;
  align-items: center;
  text-align: center;
  will-change: transform;
}
.front-heading-view .front-heading-trusted-icon {
  height: 50px;
  width: 20%;
  padding-left: 3px;
  padding-right: 3px;
  display: inline-block;
}
.front-heading-view .front-heading-trusted-icon .front-heading-trusted-image {
  max-width: 175px;
  max-height: 80px;
}
.front-heading-view .front-heading-view-search-examples {
  margin-left: 2.7rem;
}
.front-heading-view .front-heading-view-search-examples a {
  font-size: 0.9rem;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  text-decoration-color: rgb(165, 131, 255) !important;
}

@include media-breakpoint-up(md) {
  .front-heading-trusted-text {
    padding-left: 6rem;
  }
}
@include media-breakpoint-down(md) {
  .front-heading-view .front-heading-view-search-examples {
    display: none;
  }
  .front-heading-view .front-heading-text {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .front-heading-view .front-heading-trusted {
    text-align: center;
  }
  .front-heading-view .front-heading-trusted-icon {
    margin: 0 auto;
    height: 50px;
    width: 33%;
    padding-left: 3px;
    padding-right: 3px;
    display: inline-block;
  }
}
@include media-breakpoint-down(sm) {
  .front-heading-view .front-heading-text {
    font-size: 2.5rem;
  }
  .front-heading-view .front-heading-trusted-icon .front-heading-trusted-image {
    max-width: 150px;
    max-height: 70px;
  }
}
@include media-breakpoint-down(xs) {
  .front-heading-view .front-heading-text {
    font-size: 2rem;
  }
  .front-heading-view .front-heading-trusted-icon .front-heading-trusted-image {
    max-width: 105px;
    max-height: 50px;
  }
}

// How/Why
#frontHowWhyWraper {
  background-color: $white;
}
#frontHowWhyWraper .front-hw-headline {
  color: $blue;
  text-transform: uppercase;
}
#frontHowWhyWraper .front-hw-element {
  height: 300px;
  overflow: hidden;
}
#frontHowWhyWraper .front-hw-element-label {
  color: $blue;
  font-size: 1.5rem;
  font-weight: bold;
}
#frontHowWhyWraper .front-hw-element-text {
  overflow: hidden;
  font-size: 0.9rem;
}
@include media-breakpoint-up(lg) {
  #frontHowWhyWraper .front-hw-headline {
    margin-top: 3rem;
  }
}
@include media-breakpoint-down(sm) {
  #frontHowWhyWraper .front-hw-image-col {
    display: none;
  }
}
//Bottom Search
#frontBottomSearch {
  background-color: #553fed;
}

.front-search-bar-input-group .front-page-input {
  flex-grow: 1;
}

@include media-breakpoint-up(lg) {
  #frontBottomSearch .front-bs-col {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
  #frontBottomSearch .front-bs-search-container {
    padding-right: 5rem;
    padding-left: 5rem;
    padding-bottom: 5rem;
    padding-top: 5rem;
    background-color: white;
    width: 100%;
  }
  #frontBottomSearch .front-search-bar-input-group {
    width: 100%;
  }
  #frontBottomSearch .front-search-bar-input-group input {
    border: 1px solid rgba(32, 32, 34, 0.075);
    box-shadow: 0 0.5rem 1rem rgba(32, 32, 34, 0.15) !important;
  }
}
@include media-breakpoint-down(md) {
  #frontBottomSearch .front-bs-text {
    color: #fff;
  }
}

// Info Pages
.infopage-heading-view {
  margin-top: -10rem;
}
.infopage-heading-view .heading-title {
  font-size: 4.5rem;
}
.infopage-heading-subtitle {
  font-size: 2.5rem;
  text-align: center;
}

// About
.about-leadership-wrapper {
  background-color: $medium-grey;
}
.about-heading-subtitle {
  color: #2a2754;
}
.about-leadership-title {
  color: #2a2754;
}
.about-leadership-subtitle {
  color: #000;
}
.about-leadership-section {
  min-height: 400px;
}
.about-leadership-bottom-text {
  color: #000;
}
// Pricing
#pricingHeadingView {
  @include media-breakpoint-up(lg) {
    .spacer-flag {
      height: 3.5rem;
      background-color: #fafafa;
    }
    .h3 {
      font-size: 2.5rem;
    }
  }
  @include media-breakpoint-down(lg) {
    h3 {
      font-size: 1.75rem;
    }
    .spacer-flag {
      height: 0rem;
      background-color: #fafafa;
    }
  }
}
.popular-flag {
  background-color: #f97171;
  font-weight: bold;
  font-size: 1rem;
  color: $white;
}
.spacer-flag {
  height: 3.5rem;
  background-color: #fafafa;
}
.card-pricing-label {
  font-size: 2rem;
}

.card-pricing-sublabel {
  color: $black;
  font-weight: 600;
}
.pricing-heading-offers {
  min-height: 350px;
}

.pricing li {
  font-size: 14px;
  margin: 16px 0px;
  img {
    padding-right: 10px;
  }
}

.pricing-card-price {
  min-height: 3.75rem;
}
.pricing button {
  font-size: 16px;
}

@include media-breakpoint-down(md) {
  .pricing-heading-offers {
    min-height: 600px;
  }
}
.pricing-heading-subtitle {
  margin-top: 200px;
  margin-bottom: 100px;
  color: #2a2754;
}
.pricing-testimonials-title {
  color: #2a2754;
}
.pricing-testimonials-wrapper {
  background-color: $medium-grey;
}
.pricing-testimonials-section {
  min-height: 450px;
}

#contactHeadingView input[type='text'] {
  border: 1px solid $medium-grey !important;
}
#contactHeadingView textarea {
  resize: none;
  height: 150px;
  border: 1px solid $medium-grey !important;
  box-shadow: 0 2px 4px 0 rgba(222, 220, 241, 0.56);
}
#contactHeadingView .base-input-contact-form-submit {
  width: 100%;
  height: 50px;
  font-size: 1.1rem;
}

// Front page cards css
.front-heading-view-image {
  .front-page-view-attendees-btn {
    background: #ffffff;
    border: 1px solid #543fed;
    box-sizing: border-box;
    border-radius: 60px;
    color: #543fed;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: auto;
  }

  .card-background-element {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #000000;
  }

  .card {
    border: none;

    .card-body {
      display: flex;
      flex-direction: column;
    }
  }
  .front-card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  .front-card-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #656565 !important;
  }

  .card-background-element {
    height: 200px;
  }
}

.front-search-bar-input-group .front-page-input div:nth-of-type(1) {
  height: initial !important;
}

// screenwidth: 767.98px
@include media-breakpoint-down(sm) {
  .front-heading-view-image {
    .card-deck {
      flex-direction: column;

      .card {
        margin-bottom: 15px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .front-heading-view-image {
    .card-background-element {
      height: 130px;
    }

    .front-card-title {
      font-size: 16px;
      line-height: 24px;
    }
    .front-card-subtitle {
      font-size: 12px;
      line-height: 16px;
    }
    .front-card-text {
      font-size: 12px;
      margin-top: 0.5rem !important;
    }
    .front-page-view-attendees-btn {
      font-size: 12px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .front-heading-view-image {
    .card-background-element {
      height: 190px;
    }
  }
}
