/*!

=========================================================
*/
// fonts
@import 'newtheme/fonts';

// general trash
//@import 'custom/trash';

// import toggle
//@import 'custom/toggle';

// Bootstrap functions
@import 'bootstrap/functions';
@import 'bootstrap/mixins';

// Argon functions
//@import "custom/functions";
@import 'newtheme/variables';
@import 'bootstrap/variables';

// Argon mixins
//@import "custom/mixins";

// Bootstrap components
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms';
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/jumbotron';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/utilities';
@import 'bootstrap/print';

/* Vendelux Theme */
/*! purgecss start ignore */

@import 'newtheme/applayout';
@import 'newtheme/type';
//@import 'newtheme/leftnavbar';
//@import 'newtheme/usernavbar';
@import 'newtheme/dashboard';
@import 'newtheme/form';
@import 'newtheme/tables';
@import 'newtheme/eventcards';
@import 'newtheme/widgets';
@import 'newtheme/prospects';
@import 'newtheme/front';
@import 'newtheme/navbar';
@import 'newtheme/search';
@import 'newtheme/feed';
@import 'newtheme/modal';
@import 'newtheme/icons';

// React differences
@import 'react/react-differences';

/*! purgecss end ignore */
