// @font-face {
//   font-family: 'VDXLogo';
//   src: local('VDXLogo'), url('../../fonts/VDXLogo/VDXLogo.eot');
//   src: local('VDXLogo'),
//     url('../../fonts/VDXLogo/VDXLogo.eot?#iefix') format('embedded-opentype'),
//     local('VDXLogo'), url('../../fonts/VDXLogo/VDXLogo.woff') format('woff'),
//     local('VDXLogo'), url('../../fonts/VDXLogo/VDXLogo.ttf') format('truetype'),
//     local('VDXLogo'),
//     url('../../fonts/VDXLogo/VDXLogo.svg#VDXLogo') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

/* Logo */

.vdxLogo-icon:before {
  display: inline-block;
  font-family: 'VDXLogo';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\0041';
}
.vdxLogo-icon.md {
  font-size: 5rem;
}
.vdxLogo-icon.lg {
  font-size: 10rem;
}
