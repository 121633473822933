/*! purgecss start ignore */
.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  display: flex;
  padding: 0;
}

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: 0.3rem;
}

.ais-Pagination-link {
  padding: 0.3rem 0.6rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  transition: background-color 0.2s ease-out;
}

.ais-Pagination-link:focus,
.ais-Pagination-link:hover {
  background-color: #e3e5ec;
}

.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: 0.6;
  cursor: not-allowed;
  color: #a5abc4;
}

.ais-Pagination-item--disabled .ais-Pagination-link:focus,
.ais-Pagination-item--disabled .ais-Pagination-link:hover {
  color: #a5abc4;
  background-color: #fff;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #543fed;
  border-color: #543fed;
}

.ais-Pagination-item--selected .ais-Pagination-link:focus,
.ais-Pagination-item--selected .ais-Pagination-link:hover {
  color: #fff;
}

.ais-Pagination--noRefinement {
  display: none;
}

/*! purgecss end ignore */
