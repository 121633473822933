//
// Variables
//

//
// Base variables
//

// Color system

$white:    #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;   // Line footer color
$gray-700: #525f7f !default;   // Line p color
$gray-800: #32325d !default;   // Line heading color
$gray-900: #212529 !default;
$black:    #202022 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);


$blue: #462ac8;
$blue-gradient: linear-gradient(97deg, #543fed 22%, #462ac8 102%);
$light-blue: #6e7eed;
$red-purple: #cd5b8d;
$purple: #a04fd5;
$yellow-orange: #f7b500;
$red-orange: #da7263;
$orange: #de7c55;
$green: #5CB85C;
$salmon:#F97171;


$darkest-grey: #5a5878;
$darker-grey: #747195;
$medium-grey: #f0eff9;
$light-grey: #faf9ff;
$lightest-grey: #f2f1fc;


.bg-orange {
  background-color:$orange !important;
}
.bg-redpurple {
  background-color: $red-purple !important;
}
.bg-purple {
  background-color: $purple !important;
}
.bg-blue {
  background-color: $blue !important;
}




// Body

$body-bg:       $white !default;
$body-color:    $gray-700 !default;


// Import Bootstrap variable defaults

@import "../bootstrap/variables";

